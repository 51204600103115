body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Elsie-Regular;
  src: local('Elsie-Regular') url('./fonts/Elsie-Regular.ttf') format('truetype');
}

@font-face {
  font-family: FengardoNeue_Regular;
  src: local('FengardoNeue_Regular') url('./fonts/FengardoNeue_Regular.otf') format('opentype');
}

/* overrides  */
.navbar {
  padding: 2.5rem 2rem;
}

@media(max-width: 520px) {
  .navbar {
    padding: 1rem
  }
}

.active {
  border-bottom: solid 2px #F1D774;
}

a,
a:hover {
  color: black;
}

a {
  font-size: 20px;
}

a:hover:not(#alove_logo) {
  border-bottom: solid 2px #F1D774;
  text-decoration: none;
}